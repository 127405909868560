// paper & background
$paper: #ffffff;

// primary
$primaryLight: #e3f2fd;
$primaryMain: #3474ff;
$primaryDark: #407bff;
$primary200: #c4d7ff;
$primary800: #203e80;

// secondary
$secondaryLight: #ede7f6;
$secondaryMain: #673ab7;
$secondaryDark: #5e35b1;
$secondary200: #b39ddb;
$secondary800: #4527a0;

// success Colors
$successLight: #b9f6ca;
$success200: #69f0ae;
$successMain: #00e676;
$successDark: #00c853;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c62828;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #d84315;

// warning
$warningLight: #fff8e1;
$warningMain: #ffe57f;
$warningDark: #ffc107;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #1c1c1c;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}



// color variants
@import 'themes-vars.module.scss';

// third-party
@import '~react-perfect-scrollbar/dist/css/styles.css';
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';

// ==============================|| LIGHT BOX ||============================== //
.slick-arrow:before {
    color: $grey500;
}

// ==============================|| LIGHT BOX ||============================== //
.fullscreen .react-images__blanket {
    z-index: 1200;
}

// ==============================|| APEXCHART ||============================== //

.apexcharts-legend-series .apexcharts-legend-marker {
    margin-right: 8px;
}

// ==============================|| PERFECT SCROLLBAR ||============================== //

.scrollbar-container {
    .ps__rail-y {
        &:hover > .ps__thumb-y,
        &:focus > .ps__thumb-y,
        &.ps--clicking .ps__thumb-y {
            background-color: $grey500;
            width: 5px;
        }
    }
    .ps__thumb-y {
        background-color: $grey500;
        border-radius: 6px;
        width: 5px;
        right: 0;
    }
}

.scrollbar-container.ps,
.scrollbar-container > .ps {
    &.ps--active-y > .ps__rail-y {
        width: 5px;
        background-color: transparent !important;
        z-index: 999;
        &:hover,
        &.ps--clicking {
            width: 5px;
            background-color: transparent;
        }
    }
    &.ps--scrolling-y > .ps__rail-y,
    &.ps--scrolling-x > .ps__rail-x {
        opacity: 0.4;
        background-color: transparent;
    }
}

// ==============================|| ANIMATION KEYFRAMES ||============================== //

@keyframes wings {
    50% {
        transform: translateY(-40px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes blink {
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes bounce {
    0%,
    20%,
    53%,
    to {
        animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    40%,
    43% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -5px, 0);
    }
    70% {
        animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
        transform: translate3d(0, -7px, 0);
    }
    80% {
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
        transform: translateZ(0);
    }
    90% {
        transform: translate3d(0, -2px, 0);
    }
}

@keyframes slideY {
    0%,
    50%,
    100% {
        transform: translateY(0px);
    }
    25% {
        transform: translateY(-10px);
    }
    75% {
        transform: translateY(10px);
    }
}

@keyframes slideX {
    0%,
    50%,
    100% {
        transform: translateX(0px);
    }
    25% {
        transform: translateX(-10px);
    }
    75% {
        transform: translateX(10px);
    }
}

